const footer = document.querySelector('.footer [data-js-expand-menu]');

if (footer) {
  // Set up footer expanding js
  document
    .querySelectorAll('.footer [data-js-expand-menu]')
    .forEach((footerMenu) => {
      const state = {
        open: false,
      };
      const menuToggle = footerMenu.querySelector(
        '[data-js-expand-menu-toggle]',
      );
      const menuList = footerMenu.querySelector('[data-js-expand-menu-list]');

      menuList.classList.add('expand-menu');

      menuToggle.addEventListener('click', () => {
        if (state.open) {
          state.open = false;
          // Apply class to list, and to footerMenu container
          menuList.classList.remove('expand-menu--open');
          footerMenu.classList.remove('expand-menu--open');
        } else {
          state.open = true;
          // Apply class to list, and to footerMenu container
          menuList.classList.add('expand-menu--open');
          footerMenu.classList.add('expand-menu--open');
        }
      });
    });
  // Set up footer expanding js
  document.querySelectorAll('[data-js-expand-menu]').forEach((footerMenu) => {
    const state = {
      open: false,
    };
    const menuToggle = footerMenu.querySelector('[data-js-expand-menu-toggle]');
    const menuList = footerMenu.querySelector('[data-js-expand-menu-list]');

    menuList.classList.add('expand-menu');

    menuToggle.addEventListener('click', () => {
      if (state.open) {
        state.open = false;
        // Apply class to list, and to footerMenu container
        menuList.classList.remove('expand-menu--open');
        footerMenu.classList.remove('expand-menu--open');
      } else {
        state.open = true;
        // Apply class to list, and to footerMenu container
        menuList.classList.add('expand-menu--open');
        footerMenu.classList.add('expand-menu--open');
      }
    });
  });
}
